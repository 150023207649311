<template>
    <div class="scrap">
        <div class="header">
            <span @click="gouot"></span>
            <span>销售出库地磅称重</span>
            <span @click="islogout">退出</span>
        </div>

        <div class="scrap-content">
            
            <van-form>
                <div v-if="!form.id"> 
                    <van-row >
                    <van-col span="24"
                        :style="{ paddingTop:'30px',color: 'white', height: '60px', backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px' }">
                        输入运输车辆车牌号码:
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', backgroundColor: '#000000', fontSize: '40px', textAlign: 'center' }">
                        <div
                            :style="{ paddingTop: '10px', paddingLeft: '30px', paddingRight: '30px', paddingBottom: '10px' }">
                            <input hidden type="file" ref="filesRef" accept="image/*" @change="afterRead" />
                            <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                                v-model="form.carNumberPlate" name="" label="" placeholder="请输入车牌号" :disabled="isFormLock">
                                <van-button v-if="!isFormLock" slot="button" size="small"
                                    @click="isWechat ? WeChooseImage(1, '45Degrees') : $refs.filesRef.click()"
                                    type="info">车牌识别</van-button>
                            </van-field>
                        </div>
                    </van-col>
                </van-row>
            
                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', height: '30px', backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px' }">
                        输入货物名称:
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', backgroundColor: '#000000', fontSize: '40px', textAlign: 'center' }">
                        <div
                            :style="{ paddingTop: '10px', paddingLeft: '30px', paddingRight: '30px', paddingBottom: '10px' }">
                            <input hidden type="file" ref="filesRef" accept="image/*" @change="afterRead" />
                            <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                                v-model="form.goodsName" name="" label="" placeholder="请输入货物名称" :disabled="isFormLock">
                            </van-field>
                        </div>
                    </van-col>
                </van-row>

                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', height: '30px', backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px' }">
                        选择往来单位:
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', backgroundColor: '#000000', fontSize: '40px', textAlign: 'center' }">
                        <div
                            :style="{ paddingTop: '10px', paddingLeft: '30px', paddingRight: '30px', paddingBottom: '10px' }">
                            <input hidden type="file" ref="filesRef" accept="image/*" @change="afterRead" />
                            <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                                v-model="CompanyName" name="" label="" placeholder="请选择往来单位" :disabled="isCompanyNamePlantLock">
                                <van-button v-if="!isCompanyNamePlantLock && !isFormLock" slot="button" size="small"
                                    @click="chooseCompany"
                                    type="info">选择单位</van-button>
                                <van-button v-if="isCompanyNamePlantLock && !isFormLock" slot="button" size="small"
                                    @click="renewCompany"
                                    type="warning">修改单位</van-button>
                            </van-field>
                        </div>
                    </van-col>
                </van-row>


                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', backgroundColor: '#000000', fontSize: '40px', textAlign: 'center' }">
                        <div :style="{ paddingLeft: '30px', paddingRight: '30px'}">
                            <div :style="{marginTop:'30px',marginBottom:'40px'}">
                                <van-button v-if="!isFormLock" round block type="info" @click="createOrder">新建磅单</van-button>
                            </div>
                        </div>
                    </van-col>
                </van-row>
                </div>
                
                
                <van-row v-if="form.id" :style="{ marginTop: '10px' }" >
                    <van-col span="20">
                        <div :style="{ color: 'white', height: '30px',backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px', paddingTop: '20px'}">
                            磅单号码：{{ addZero(form.id) }}
                        </div>
                        <div :style="{ color: 'white', height: '30px',backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px'}">
                            车牌号码：{{ form.carNumberPlate }}
                        </div>
                        <div :style="{ color: 'white', height: '30px',backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px'}">
                            货物名称：{{ form.goodsName }}
                        </div>
                        <div :style="{ color: 'white', height: '30px',backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px', paddingBottom: '20px'}">
                            往来单位：{{ CompanyName }}
                        </div>
                    </van-col>
                    <van-col span="4" :style="{color: 'white',height: '160px',backgroundColor: '#000000',textAlign:'right'}">
                        <span @click="renewOrder" :style="{writingMode:'vertical-rl', color: 'yellow',height: '160px',backgroundColor: 'green',letterSpacing:'5px',lineHeight:'50px',textAlign:'center'}">
                            重新录单
                        </span>
                    </van-col>
                </van-row>


                <van-row v-if="form.id" :style="{ marginTop: '10px' }">
                    <van-col span="24"
                        :style="{ color: 'white', height: '30px', backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px'}">
                        地磅称重数值:
                    </van-col>
                </van-row>
                <van-row v-if="form.id">
                    <van-col span="24"
                        :style="{ color: '#90ee90', height: '60px', backgroundColor: '#000000', fontSize: '30px', textAlign: 'center', lineHeight: '60px' }">
                        {{ weighBridgeValue == '无数据' || weighBridgeValue == '等待新信号' ? weighBridgeValue : weighBridgeValue + 'Kg' }}
                    </van-col>
                </van-row>
                <van-row v-if="form.id">
                    <van-col span="24"
                        :style="{ color: 'white', height: '40px', backgroundColor: '#000000', fontSize: '20px', textAlign: 'center', lineHeight: '40px' }">
                        地磅状态：{{ weighBridgeStatus }}
                    </van-col>
                </van-row>


                <van-row v-if="form.id && form.status>=0" :style="{ marginTop: '10px' }">
                    <van-col span="24"
                        :style="{ color: 'white', height: '50px',fontSize: '30px', backgroundColor: 'red', textAlign: 'left', lineHeight: '50px', paddingLeft: '30px'}">
                        皮重:{{ form.cutWeight }}Kg
                    </van-col>
                </van-row>
                
                <van-row v-if="form.id && form.status==1" :style="{ marginTop: '0px' }">
                    <van-col span="24"
                        :style="{ color: 'white', height: '50px',fontSize: '30px', backgroundColor: 'red', textAlign: 'left', lineHeight: '50px', paddingLeft: '30px'}">
                        毛重:{{ form.weight }}Kg
                    </van-col>
                </van-row>

                <van-row v-if="form.id && form.status==1" :style="{ marginTop: '0px' }">
                    <van-col span="24"
                        :style="{ color: 'white', height: '50px',fontSize: '30px', backgroundColor: 'red', textAlign: 'left', lineHeight: '50px', paddingLeft: '30px'}">
                        净重:{{ ((form.weight*1000000-form.cutWeight*1000000)/1000000) }}Kg
                    </van-col>
                </van-row>

                <div style="margin: 50px 16px 16px;">
                    <van-button v-if="form.id && form.status==0" round block type="info" @click="CPOrder" :disabled="weighBridgeStatus!='稳定'">存皮过磅</van-button>
                </div>

                <div v-if="form.id && form.status==1" style="margin: 50px 16px 16px;">
                    <van-row :style="{ marginTop: '0px' }">
                        <van-col span="11">
                            <van-button round block type="warning" @click="newOrder">新车存皮</van-button>
                        </van-col>
                        <van-col span="2"></van-col>
                        <van-col span="11">
                            <van-button round block type="info" @click="CMOrder" :disabled="weighBridgeStatus!='稳定' && form.weight<=form.cutWeight">重车过磅</van-button>
                        </van-col>
                    </van-row>
                    <van-row :style="{ marginTop: '20px' }">
                        <van-col span="24">
                            <van-button round block type="danger" @click="gouot">退出过磅</van-button>
                        </van-col>
                    </van-row>
                </div>
            </van-form>
        </div>
        <van-popup v-model="show" :close-on-click-overlay="false" class="loading"><van-loading
                size="40px">上传中。。。</van-loading></van-popup>


        <van-popup v-model="showCompany" position="bottom" :close-on-click-overlay="false">
            <van-picker show-toolbar :columns="CompanyPickerData" value-key="shopName" @confirm="onPickerConfirm($event)"
                @cancel="showCompany = false" />
        </van-popup>
    </div>
</template>
<script>

</script>

<script>
import UploadImg from "../../components/upload_img";
import { Toast ,Dialog} from 'vant'
import { is_post } from "../../Http/api";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import AppVue from '../../App.vue';
    export default {
        components: {
            UploadImg,
        },
        data() {
            return {
                CompanyName:'',
                CompanyPickerData:[],
                showCompany:false,
                showDateTime:false,
                currentDate:new Date(),
                isCarInfo:true,
                isPriceInfo:false,
                isImagesInfo:false,
                isConnectInfo:false,
                isTowage:false,
                uploader:[],
                show:false,//上传照片加载
                showMap:false,//地图
                second_show:false,//验证码
                content_show:false,//提交完成
                weighBridgeValue:"无数据",
                weighBridgeStatus:"无数据",
                carNumberPlate:'',
                form:{
                    id:'',
                    status:0,
                    carNumberPlate:"",//车牌号
                    purchasingChannelsId:'',//往来单位id
                    goodsName:'',//货物名称
                    weight:0,
                    cutWeight:0,
                },
                WXimg:[],
                wximagelocalids:[],//判断上传张数
                toast:null,
                isFormLock:false,
                isCompanyNamePlantLock:false,
            }
        },
        created(){
        },
        mounted () {
            this.wcConfig()
            let that=this
            this.GLOBALBUS.$on('watch_WS_MSG', (skt_message) => {
            // 具体函数内容
                //console.log("收到socket消息",skt_message)
                if(skt_message.code==0){
                    switch(skt_message.data.type){
                        case "login":
                            this.getWeightData()
                            break
                        case "autoWeight":
                            if(skt_message.data.error==""){
                                that.weighBridgeValue="无数据"
                                that.weighBridgeStatus="无数据"
                            }else{
                                let weight=skt_message.data.weight
                                let status=skt_message.data.status
                                that.weighBridgeValue=weight
                                if(status){
                                    that.weighBridgeStatus="稳定"
                                }else{
                                    that.weighBridgeStatus="正在称重"
                                }
                                if(weight==0){
                                    that.weighBridgeStatus="无重物"
                                }                                
                            }
                            break
                    }
                }else{
                    switch(skt_message.type){
                        case "autoWeight":
                            if(skt_message.error!=""){
                                that.weighBridgeValue="无数据"
                                that.weighBridgeStatus="无数据"
                            }else{
                                let weight=skt_message.weight
                                let status=skt_message.status
                                that.weighBridgeValue=weight
                                if(status){
                                    that.weighBridgeStatus="稳定"
                                }else{
                                    that.weighBridgeStatus="正在称重"
                                }
                                if(weight==0){
                                    that.weighBridgeStatus="无重物"
                                }                                
                            }
                            
                            break
                    }
                }

            })
            this.GLOBALBUS.$on('watch_WS_STATUS', (ws_status) => {
            // 具体函数内容
                if(ws_status){
                    console.log("socket全局连接成功")
                    this.getWeightData()
                }else{
                    console.log("socket全局连接失败")
                }
            })
            this.getWeightData()
        },
        watch: {
            weighBridgeValue:{
                handler(newValue, oldValue){
                    if(this.form.id && this.form.status==0){
                        this.form.cutWeight=this.numberFormat(newValue)
                    }

                    if(this.form.id && this.form.status==1){
                        this.form.weight=this.numberFormat(newValue)
                    }
                },
                deep: true,
            },
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                        this.toast = Toast.loading({
                            duration: 0, // 持续展示 toast
                            forbidClick: true,
                            message: "上传中..."
                        });
                        this.wxUpload(newValue[0])
                    }else{
                        this.toast.clear();
                    }
                },
                deep: true,
            },
            form:{
                async handler(newValue, oldValue){
                    if(newValue.carNumberPlate != this.carNumberPlate ){
                        this.carNumberPlate=newValue.carNumberPlate
                        let data = encipherMent( JSON.stringify({carNumberPlate:newValue.carNumberPlate}))
                        let param = new URLSearchParams();
                        param.append("value",data);
                        let url='/index.php/index/Sale_Weight/inComplete'
                        const res = await is_post( url,param)
                        if(res.code == 0){
                            if(res.data){
                                Dialog.confirm({
                                    title: '温馨提示',
                                    cancelButtonText:'放弃磅单',
                                    confirmButtonText:'重车过磅',
                                    message: '车牌号【'+this.form.carNumberPlate+'】已有今日存皮磅单，尚未重车过磅，货物为【'+res.data.goodsName+'】,往来单位为【'+res.data.purchasingChannelsText+'】,是否继续重车过磅？',
                                })
                                .then(async() => {
                                    this.isFormLock=true
                                    this.isCompanyNamePlantLock=true
                                    this.CompanyName=res.data.purchasingChannelsText
                                    this.form.carNumberPlate=res.data.carNumberPlate
                                    this.form.id=res.data.id
                                    this.form.goodsName=res.data.goodsName
                                    this.form.status=res.data.status
                                    this.form.weight=this.numberFormat(this.weighBridgeValue)
                                    this.form.cutWeight=res.data.cutWeight
                                    this.purchasingChannelsId=res.data.purchasingChannelsId
                                    this.$toast.success("请继续录单")
                                })
                                .catch(async() => {
                                    // on cancel
                                    // on confirm
                                    let url='/index.php/index/Sale_Weight/delOrder'
                                    let data = encipherMent( JSON.stringify(res.data))
                                    let param = new URLSearchParams();
                                    param.append("value",data);
                                    const res = await is_post( url,param)
                                    if(res.code == 0){
                                        this.$toast.success("请继续录单")
                                    }else{
                                        this.$toast.fail(res.msg)
                                    }
                                });
                            }
                        }
                    }
                },
                deep: true,
            },
        },
        methods: {
            numberFormat(str){
                return isNaN(Number(str))?0:Number(str)
            },
            addZero(num) {
                return 'XS-'+('000000000' + num).slice(-10)
            },
            async createOrder(){
                if(!this.form.carNumberPlate){
                    this.$toast.fail("请输入车牌号码")
                    return false
                }
                if(!this.form.goodsName){
                    this.$toast.fail("请输入货物名称")
                    return false
                }
                if(!this.form.purchasingChannelsId){
                    this.$toast.fail("请选择往来单位")
                    return false
                }
                let data = encipherMent(JSON.stringify(this.form))
                let param = new URLSearchParams();
                param.append("value",data);
                let url='/index.php/index/Sale_Weight/submitOrder'
                const res = await is_post( url,param)
                if(res.code == 0){
                    this.isFormLock=true
                    this.form.id=res.data
                    this.form.cutWeight=this.numberFormat(this.weighBridgeValue)
                }else{
                    this.$toast.fail(res.msg)
                }
            },
            async CPOrder(){
                if(!this.form.carNumberPlate){
                    this.$toast.fail("请输入车牌号码")
                    return false
                }
                if(!this.form.goodsName){
                    this.$toast.fail("请输入货物名称")
                    return false
                }
                if(!this.form.purchasingChannelsId){
                    this.$toast.fail("请选择往来单位")
                    return false
                }
                let data = encipherMent( JSON.stringify(this.form))
                let param = new URLSearchParams();
                param.append("value",data);
                let url='/index.php/index/Sale_Weight/CPOrder'
                const res = await is_post( url,param)
                if(res.code == 0){
                    this.form.status=1
                    this.isFormLock=true
                    this.form.cutWeight=res.data
                    this.form.weight=this.numberFormat(this.weighBridgeValue)
                    
                    this.$toast.success("存皮成功")
                    this.success_cp()
                }else{
                    this.$toast.fail(res.msg)
                }
            },
            async CMOrder(){
                if(!this.form.carNumberPlate){
                    this.$toast.fail("请输入车牌号码")
                    return false
                }
                if(!this.form.goodsName){
                    this.$toast.fail("请输入货物名称")
                    return false
                }
                if(!this.form.purchasingChannelsId){
                    this.$toast.fail("请选择往来单位")
                    return false
                }
                if(!this.form.cutWeight>=this.form.weight){
                    this.$toast.fail("毛重必须大于皮重")
                    return false
                }
                let data = encipherMent( JSON.stringify(this.form))
                let param = new URLSearchParams();
                param.append("value",data);
                let url='/index.php/index/Sale_Weight/CMOrder'
                const res = await is_post( url,param)
                if(res.code == 0){
                    this.success()
                }else{
                    this.$toast.fail(res.msg)
                }
            },
            newOrder(){
                Dialog.confirm({
                    title: '温馨提示',
                    message: "确定新建磅单？",
                })
                .then(async() => {
                    this.isFormLock=false
                    this.isCompanyNamePlantLock=false
                    this.CompanyName=''
                    this.form.carNumberPlate=''
                    this.form.id=''
                    this.form.goodsName=''
                    this.form.status=0
                    this.form.weight=0
                    this.form.cutWeight=0
                    this.purchasingChannelsId=''
                    this.$toast.success("请继续录单")
                })
                .catch(() => {
                    
                });
            },
            success(){
                Dialog.confirm({
                    title: '温馨提示',
                    message: "过磅成功,是否继续过磅？",
                })
                .then(async() => {
                    this.isFormLock=false
                    this.isCompanyNamePlantLock=false
                    this.CompanyName=''
                    this.form.carNumberPlate=''
                    this.form.id=''
                    this.form.goodsName=''
                    this.form.status=0
                    this.form.weight=0
                    this.form.cutWeight=0
                    this.purchasingChannelsId=''
                    this.$toast.success("请继续录单")
                })
                .catch(() => {
                    this.gouot()
                });
            },
            onPickerConfirm(e) {
                this.isCompanyNamePlantLock=true
                this.showCompany=false
                this.CompanyName=e.shopName
                this.form.purchasingChannelsId=e.id
            },
            renewCompany(){
                this.isCompanyNamePlantLock=false
                this.showCompany=false
                this.CompanyName=''
                this.form.purchasingChannelsId=''
            },
            async chooseCompany(){
                if(!this.CompanyName){
                    this.$toast.fail("请输入往来单位关键词")
                    return false
                }
                let data = encipherMent( JSON.stringify({name:this.CompanyName}))
                let param = new URLSearchParams();
                param.append("value",data);
                let url='/index.php/filter/Companylist'
                const res = await is_post( url,param)
                if(res.code == 0){
                    if(res.data.data.length){
                        this.CompanyPickerData=res.data.data
                        this.showCompany=true
                    }else{
                        this.$toast.fail("未找到往来单位")
                    }
                }else{
                    this.$toast.fail(res.msg)
                }
            },
            confirmOrder(){
                this.isFormLock=true
            },
            renewOrder(){
                Dialog.confirm({
                    title: '温馨提示',
                    message: '重新录单会删除当前磅单，确定要重新录单吗？',
                })
                .then(async() => {
                    // on confirm
                    let url='/index.php/index/Sale_Weight/delOrder'
                    let data = encipherMent( JSON.stringify(this.form))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post( url,param)
                    if(res.code == 0){
                        this.isFormLock=false
                        this.isCompanyNamePlantLock=false
                        this.CompanyName=''
                        this.form.carNumberPlate=''
                        this.form.id=''
                        this.form.goodsName=''
                        this.form.status=0
                        this.form.weight=0
                        this.form.cutWeight=0
                        this.purchasingChannelsId=''
                        this.$toast.success("请重新录单")
                    }else{
                        this.$toast.fail(res.msg)
                    }
                })
                .catch(() => {
                    // on cancel
                });
            },
            getWeightData(){
                if(window.WS.readyState){
                    let loginCallBack={
                        platform:9999,
                        shopId:window.localStorage.getItem("shopId"),
                        type:"weightCallBack",
                        id:window.localStorage.getItem("WSID"),
                    }
                    window.WS.send(JSON.stringify(loginCallBack))
                }
            },
            
            gouot() {
                this.$router.push('/guide')
            },
            
            //上传照片
            afterRead(e){
                let file=e.target.files[0]
                /* console.log("file",e.target.files[0]) */
                this.show = true
                let params = new FormData(); //创建form对象
                params.append("file", file);
                params.append("w", 200);
                params.append("h", 200);
                this.$http
                    .post("/index.php/index/upload/index", params)
                    .then((res) => {
                        if (res.data.code == 0) {
                      
                       this.CarNumberPlateDiscern(res.data.data)
                    } else {
                        this.show= false
                       
                       this.$toast.fail(res.data.msg)
                    }
                })
                .catch(()=>{
                    
                })
            },
            //识别车牌
            async CarNumberPlateDiscern(img){
                try{
                    let data = encipherMent( JSON.stringify({path:img}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post( "index.php/vehicles_upload/vehicleCarNumberPlate",param)
                    console.log(res)
                    this.form.carNumberPlate = res.data.carNumberPlate
                    this.show = false
                }
                catch{
                    this.show = false
                }
               
            }
        },
    }
</script>
<style>
.van-field__control:disabled {
  color: green !important;
  -webkit-text-fill-color: green !important;
  font-weight: 800;
}
</style>
<style lang="scss" scoped>
.scrap {

    // padding: 10px;
    .title {
        text-align: center;
        font-size: 26px;
        margin-top: 10px;
        padding: 10px;
    }

    .scrap-content {
        margin-top: 70px;

        .scrap-content-div {
            margin-bottom: 20px;
            margin: 15px 15px;

            input: {
                border: 0px solid green;
                border-bottom-width: 1px;
            }
        }
    }

    .btn-p {
        margin: 5px;
    }
}

.van-loading {
    height: 75px !important;
    background: rgba(0, 0, 0, 0.7);
}

.accomplish {
    width: 80%;
    font-size: 26px;
    padding: 20px;
    margin-right: 0 !important;

    .accomplish-title {
        text-align: center;
    }

    .accomplish-zi {
        text-indent: 2em;
    }
}

.btn-Vin {
    margin: 10px;

}

.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;

    span {
        display: inline-block;
    }

    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}

.uploaderImg {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 1px solid #eee;
    position: relative;

    .iconz {
        position: absolute;
        top: 42%;
        left: 38%;

    }
}

.vanImage {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;

    .iconX {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>

<template>
  <van-uploader
    v-model="images"
    :after-read="afterRead"
    :max-count="maxCount"
    result-type="text"
  />
</template>


<script>
export default {
  props: ["maxCount"],
  data: function () {
    return {
      images: [],
      imagesUrlList: [],
    };
  },
  methods: {
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 200);
      this.$http
        .post("/index.php/index/upload/index", params)
        .then((res) => {
            console.log(res)
          if (res.data.code == 0) {
            file.content = res.data.data;
            file.status = "done";
          } else {
            file.status = "failed";
            file.message = res.data.msg;
          }
        }).catch(()=>{
          file.status = "failed";
            file.message =  res.data.msg;
        })
    },
  },
  watch: {
    images: {
      handler(newVal, oldVal) {
        newVal.forEach((obj, i) => {
     
          if(obj.url){

            this.imagesUrlList[i] = obj.url;
          }else{

            this.imagesUrlList[i] = obj.content;
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>